import '@babel/polyfill'
import ReactDOM from 'react-dom'

const paramsForm = document.getElementById('window_params')
if (paramsForm) {
  for (let i = 0; i < paramsForm.elements.length; i++) {
    const element = paramsForm[i]
    window[element.name] = element.value
  }
}

(window.endpoint === 'login' ? import('./Login') : import('./Routes'))
  .then(routes => {
    const root = document.getElementById('root')
    if (routes && root) {
      ReactDOM.render(routes.default, root)
    }
  })
